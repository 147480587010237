import { FlexBox } from 'components/layouts/FlexBox';
import { UserLink } from 'components/legacy/Links/UserLink';
import { VerifiedBadge } from 'components/globals/VerifiedBadge';
import React from 'react';
import * as S from './OnlineFriends.styles';
import { OnlineFriendProps } from './OnlineFriends.types';

export const OnlineFriend = ({ username, pictureUrl, isVerified, isEmployee, onUserClick }: OnlineFriendProps) => {
  return (
    <S.OnlineCard data-testid="online-friend">
      <FlexBox>
        <UserLink username={username}>
          <S.Username data-testid="online-friend-username" onClick={onUserClick}>
            {username}
          </S.Username>
        </UserLink>
        <VerifiedBadge isEmployee={isEmployee} isVerified={isVerified} size={20} />
      </FlexBox>
      <UserLink username={username}>
        <S.BigProfilePicture src={pictureUrl} onClick={onUserClick} alt={`Visit ${username}'s profile`} />
      </UserLink>
    </S.OnlineCard>
  );
};
