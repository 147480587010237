import styled from 'styled-components';

export const ModuleWrapper = styled.div`
  padding: 1rem;
  width: 100%;
  max-width: 1440px;

  @media (min-width: ${({ theme }) => theme.breakpoint.medium}) {
    padding: 1rem 2rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.large}) {
    padding: 1rem 4rem;
  }
`;
