import { Play } from '@hiberworld/icons';
import { FlexBox } from 'components/layouts/FlexBox';
import { ProjectLink } from 'components/legacy/Links/ProjectLink';
import { UserLink } from 'components/legacy/Links/UserLink';
import { SmallButton } from 'components/cards/SessionCard/SessionCard.styles';
import { VerifiedBadge } from 'components/globals/VerifiedBadge';
import { useDive } from 'hooks/useDive';

import { useState } from 'react';
import * as S from './OnlineFriends.styles';
import { PlayingFriendProps } from './OnlineFriends.types';

export const PlayingFriend = ({
  user: { username, pictureUrl, isVerified, isEmployee, multiplayerSession, activeSession },
  onSessionClick,
  onProjectClick,
  onUserClick,
}: PlayingFriendProps) => {
  const [joining, setJoining] = useState(false);
  const dive = useDive();

  function joinOldEngineSession() {
    setTimeout(() => {
      if (multiplayerSession?.sessionUrl) {
        window.location.href = multiplayerSession.sessionUrl;
      }
    }, 0);
  }

  const isDao = !multiplayerSession;
  const currentProject = isDao ? activeSession?.project : multiplayerSession.currentProject;
  const projectId = currentProject?.id;
  const thumbnail = currentProject?.thumbnail;
  const title = currentProject?.title;

  const handleJoin = () => {
    onSessionClick?.();
    setJoining(true);
    if (isDao) {
      if (activeSession?.sessionUrl) {
        dive.trackGameInit(projectId ?? '');
        window.location.href = activeSession.sessionUrl;
      }
    } else {
      joinOldEngineSession();
    }
    setJoining(false);
  };

  return (
    <S.PlayingCard thumbnail={thumbnail} data-testid="online-friend">
      <S.InfoRow>
        <UserLink username={username}>
          <S.ProfilePicture src={pictureUrl} onClick={onUserClick} alt={`Visit ${username}'s profile`} />
        </UserLink>
        <FlexBox justifyContent="space-between" direction="column">
          <UserLink username={username}>
            <S.Username data-testid="online-friend-username" onClick={onUserClick}>
              {username}
              <VerifiedBadge isEmployee={isEmployee} isVerified={isVerified} size={20} />
            </S.Username>
          </UserLink>
          <S.SubTitle>is playing</S.SubTitle>
        </FlexBox>
      </S.InfoRow>

      <ProjectLink id={projectId ?? ''}>
        <S.Title onClick={onProjectClick}>{title}</S.Title>
      </ProjectLink>
      <SmallButton text="Join" className="red" iconLeft={<Play title="Join" />} onClick={handleJoin} busy={joining} />
    </S.PlayingCard>
  );
};
