import Link from 'next/link';
import Base64 from 'utils/Base64';
import styled from 'styled-components';
import * as S from './Links.styles';
import { ProjectLinkProps } from './Links.types';

const ClickWrapper = styled.div``;

export const ProjectLink = ({ id, children, overrideColor, bold, onLinkClick }: ProjectLinkProps) => {
  return (
    <ClickWrapper onClick={onLinkClick}>
      <Link href={`/world/${Base64.fromNumber(id)}`} passHref legacyBehavior prefetch={false}>
        <S.Container data-testid="project-link" bold={bold} overrideColor={overrideColor}>
          {children}
        </S.Container>
      </Link>
    </ClickWrapper>
  );
};
