import { FlexBox } from 'components/layouts/FlexBox';
import { LoadingCard } from 'components/common/SkeletonLoader';
import { Spacer } from 'components/layouts/Spacer';
import { Heading } from 'components/typography';
import { Project, Session, SessionType, useOnlineFriendsQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { ENGINE_TRACK_URL } from 'hooks/useDive/useDive.constants';
import { useRouter } from 'next/router';
import { ModuleWrapper } from '../PageModule.styles';
import { PageModuleTypeProp } from '../PageModule.types';
import { OnlineFriend } from './OnlineFriend';
import * as S from './OnlineFriends.styles';
import { PlayingFriend } from './PlayingFriend';

type JoinableSession = Pick<Session, 'type'> & { project: Pick<Project, 'visibility'> };
const joinable = (session: JoinableSession) =>
  session?.type !== SessionType.Creative && session?.project?.visibility === 2;

export const UserFeedOnlineFriends = ({
  module,
  name,
}: {
  module: PageModuleTypeProp<'UserFeedOnlineFriends'>;
  name: string;
}) => {
  const { trackUserInteract } = useDive();
  const router = useRouter();

  const { data, loading } = useOnlineFriendsQuery({ fetchPolicy: 'cache-only' });

  const friends = data?.onlineFriends;
  const trackUserCardClick = (userId: string, destination: string) => {
    trackUserInteract(destination, router.asPath, name, 'feed', userId);
  };

  if (loading) {
    return (
      <FlexBox justifyContent="center">
        <ModuleWrapper>
          <S.OuterRow>
            <FlexBox justifyContent="center">
              <Heading size="h3" as="h2">
                {module.title}
              </Heading>
            </FlexBox>
            <Spacer height={24} />
            <S.Row>
              {new Array(7).fill(0).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <LoadingCard height={140} width={190} borderRadius={8} key={`OnlineFriendLoading-${i}`} />
              ))}
            </S.Row>
          </S.OuterRow>
        </ModuleWrapper>
      </FlexBox>
    );
  }

  if (!friends || friends.length === 0) {
    return null;
  }

  // Sort friends by preferring the existence of multiplayer session.
  const sortedFriends = friends
    .slice()
    .sort((f1, f2) => +!!f2.user.activeSession - +!!f1.user.activeSession)
    .sort((f1, f2) => +!!f2.user.multiplayerSession - +!!f1.user.multiplayerSession);

  return (
    <FlexBox justifyContent="center">
      <ModuleWrapper>
        <S.OuterRow>
          <FlexBox justifyContent="center">
            <Heading size="h2" as="h2">
              {module.title}
            </Heading>
          </FlexBox>
          <Spacer height={24} />
          <S.Row>
            {sortedFriends.map(({ user }) => {
              const { username, pictureUrl, isEmployee, isVerified } = user;
              const showPlayingOld = !!user?.multiplayerSession;
              const showPlayingNew = Boolean(
                user?.activeSession &&
                  joinable(user?.activeSession) &&
                  user?.activeSession?.players &&
                  user.activeSession.players?.length > 0
              );

              if (showPlayingOld || showPlayingNew) {
                return (
                  <PlayingFriend
                    onSessionClick={() => trackUserCardClick(user.id, ENGINE_TRACK_URL)}
                    onProjectClick={() => trackUserCardClick(user.id, ENGINE_TRACK_URL)}
                    onUserClick={() => trackUserCardClick(user.id, ENGINE_TRACK_URL)}
                    user={user}
                    key={`PlayingFriend-${user.id}`}
                  />
                );
              }
              return (
                <OnlineFriend
                  onUserClick={() => trackUserCardClick(user.id, `/user/${user.username}`)}
                  username={username}
                  pictureUrl={pictureUrl}
                  isEmployee={isEmployee}
                  isVerified={isVerified}
                  key={`OnlineFriend-${user.id}`}
                />
              );
            })}
          </S.Row>
        </S.OuterRow>
      </ModuleWrapper>
    </FlexBox>
  );
};

export default UserFeedOnlineFriends;
